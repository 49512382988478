import React from "react";
import Logo from "../assets/img/QuaiSwap.png";
import Quai from "../assets/img/quai.png";
import { Link } from "react-router-dom";
import telegram from "../assets/img/telegram.png";
function Header(props) {
    const { address, isConnected, connect } = props;

    return (
        <header>
            <div className="leftH">
                <img src={Logo} alt="logo" className="logo" />
                <Link to="/" className="link">
                    <div className="headerItem">Swap</div>
                </Link>
                <Link to="/liquidity" className="link">
                    <div className="headerItem">Liquidity</div>
                </Link>
                <Link to="/farms" className="link">
                    <div className="headerItem">Farms</div>
                </Link>
                <Link to="/airdrop" className="link">
                    <div className="headerItem">QUAI Airdrop</div>
                </Link>
                {/*<Link to="/migrate" className="link">
                    <div className="headerItem">Token Migration (Testing)</div>
                </Link>*/}
            </div>

            <div className="rightH">
            <a href="https://t.me/quaiswapexchange" target="_blank" rel="noopener noreferrer">
                        <img src={telegram} alt="Telegram" className="telegram" style={{ width: "25px", height: "25px", marginTop: "5px", cursor: "pointer" }} />
                    </a>
                <div className="headerItem">
                
                    <img src={Quai} alt="quai" className="eth"/>
                        Quai
                </div>
                <div className="connectButton" onClick={connect}>
                    {
                        isConnected && address != undefined? address.slice(0, 6) + "..." + address.slice(38) : "Connect"
                    }
                </div>
            </div>
        </header>
    );
}

export default Header;
